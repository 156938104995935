import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HopDebugToolComponent } from './hop-debug-tool.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [HopDebugToolComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatCheckboxModule],
  exports: [HopDebugToolComponent]
})
export class HopDebugToolModule {}
