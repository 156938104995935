import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then((m) => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule)
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'apps',
        children: [
          {
            path: 'locations',
            loadChildren: () => import('./pages/apps/locations/location.module').then((m) => m.LocationModule)
          },
          {
            path: 'accommodations',
            loadChildren: () => import('./pages/apps/accommodations/accommodation.module').then((m) => m.AccommodationModule)
          },
          {
            path: 'rooms',
            loadChildren: () => import('./pages/apps/rooms/room.module').then((m) => m.RoomModule)
          },
          {
            path: 'meals',
            loadChildren: () => import('./pages/apps/meals/meal.module').then((m) => m.MealModule)
          },
          {
            path: 'pages',
            loadChildren: () => import('./pages/apps/pages/page.module').then((m) => m.PageModule)
          },
          {
            path: 'reservations',
            loadChildren: () => import('./pages/apps/reservations/reservation.module').then((m) => m.ReservationModule)
          },
          /* {
            path: "aio-table",
            loadChildren: () =>
              import("./pages/apps/aio-table/aio-table.module").then(
                (m) => m.AioTableModule
              ),
          }, */
          /* {
            path: "editor",
            loadChildren: () =>
              import("./pages/apps/editor/editor.module").then(
                (m) => m.EditorModule
              ),
          }, */
          {
            path: 'users',
            loadChildren: () => import('./pages/apps/users/user.module').then((m) => m.UserModule)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then((m) => m.ComponentsModule)
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then((m) => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then((m) => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then((m) => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then((m) => m.PageLayoutsModule)
          }
        ]
      },
      {
        path: '',
        loadChildren: () => import('./pages/apps/dashboard-analytics/dashboard-analytics.module').then((m) => m.DashboardAnalyticsModule)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
